.main {
    background-color: #181924;
}

label {
    font-size: 14px;
}

.clientDataWarp {
    font-weight: 500;
    padding-top: 5px;
}

.version-warp {
    font-size: 13px;
    position: absolute;
    bottom: 0px;
    left: 47%;
    color: rgb(152, 152, 158);
}


@media (min-width:380px) and (max-width:765px) {
    .card {
        width: 23rem;
        transform: 5s ease-in-out;
    }

    .version-warp {
        left: 41%;
    }
}

@media (max-width:380px) {
    .card {
        width: 22rem;
        transform: 5s ease-in-out;
    }

    .version-warp {      
        left: 40%;
    }
}

@media (min-width:765px) {
    .card {
        width: 30rem;
        transform: 5s ease-in-out;
    }

    .version-warp {
        left: 47%;
    }
}