.warn-sd834-height {
    height: 85vh;
}

.warn-sbd83-logo {
    width: 400px;
}

.warn-ba39-text {
    font-size: 1.7rem;
}

.warn-3b7d-mailtext {
    font-size: 1.4rem;
}

.warn-dstw98-timetext {
    font-size: 1.3rem;
}

@media (min-width:380px) and (max-width:765px) {
    .warn-sbd83-logo {
        width: 300px;
    }
    .warn-ba39-text {
        font-size: 1.4rem;
    }
}

@media (max-width:380px) {
    .warn-sbd83-logo {
        width: 190px;
    }
    .warn-ba39-text {
        font-size: 1.4rem;
    }
}

@media (min-width:765px) {
    .warn-sbd83-logo {
        width: 350px;
    }
    .warn-ba39-text {
        font-size: 1.8rem;
    }
}